import React, { useState } from 'react';
import './App.css';
import BackgroundVideo from './components/BackgroundVideo';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const [name, setName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  function handleBirthdays() {}

  return (
    <>
      <BackgroundVideo />

      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <div className="box">
                <h4 className="title is-4 has-text-centered" id="title">
                  Dog Birthday Calculator
                </h4>
                <div className="column is-6 is-m-auto">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal has-text-centered">
                      <label className="label" htmlFor="name">
                        Dog's Name
                      </label>
                      <div className="control">
                        <input
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          className="form-control input is-medium"
                          id="name"
                          placeholder="Enter your Dog's Name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal has-text-centered has-icons-left">
                    <label className="label" htmlFor="dogBirthday">
                      Dog's Date of Birth
                    </label>
                    <DatePicker
                      className="form-control input is-medium"
                      placeholder="Enter your Dog's Birthday"
                      selected={dateOfBirth}
                      maxDate={new Date()}
                      onChange={(date) => setDateOfBirth(date)}
                    />
                  </div>
                </div>
                <br />

                <div className="field has-addons has-addons-centered">
                  <p className="control">
                    <button
                      onClick={() => handleBirthdays()}
                      className="button is-white is-outlined has-addons-centered is-medium"
                      id="calculate"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      Calculate
                    </button>
                  </p>
                </div>

                <div className="panel panel-default" id="bDayList">
                  <p className="title is-4" id="modaltitle"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
